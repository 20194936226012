const atomicDesignInit = async () => {
    const articleGalleryBigElement = document.querySelector('[data-atomic-design]');

    if (articleGalleryBigElement) {
        const { atomicDesign } = await import(
            /* webpackChunkName: 'chunk-atomicDesign' */
            './modules/atomic-design/atomicDesign.ts'
        );

        atomicDesign();
    }
};

/** Spusteni dynamickych importu */
const loadDynamicImports = () => {
    atomicDesignInit();
};

loadDynamicImports();
